.rules{
    width:500px;
    margin:0 auto;
    width: 45vw;
    padding-top:60px;
}



.steps{
    margin-top:20px;
    width:100%;
    display:flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

}

.step_item{
    width:360px;
    background-color:white;
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top:40px;
    padding-top:40px;
}

.step_item img{
    width:120px;
    height:120px;
}

.step_item:last-child img{
    width:auto;
    max-width: 100%;
    height:100px;
}

.step_item:last-child  h3{
    margin-top:39px;
}

.step_desc{
    margin-left:20px;
    margin-right: 20px;;
}


.step_desc p{
    font-family: 'Gotham Light', sans-serif;
}

@media only screen and (max-width: 1125px) {
    .rules{
        width:90vw;
    }

}

