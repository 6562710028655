header{
    background-image: url('../assets/hero.jpg');
    background-repeat: no-repeat;
    background-size:cover;
    width:100vw;
    min-height:40vh;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

@media only screen and (max-width: 1125px) {
    header{
        background: rgb(0, 0, 0);
        background-image:url("../assets/logo_akcja.png"),linear-gradient(180deg, rgba(0, 0, 0, 1) 16%, rgba(209, 209, 209, 1) 100%);
        background-position-y: center;
        background-position-x: center;
        background-size:auto;
        background-repeat: no-repeat;
    }

    header::before{
        content: "";
        position:absolute;
        background-color: #F0F0F0;
        width:100vw;
        height:60px;
        top:35vh;
        clip-path: polygon(0 0, 100% 71%, 100% 100%, 0% 100%);
    }

}

nav{
    font-family: 'Gotham Book', sans-serif;
    min-height: 50px;
    width: 100vw;
    border-bottom:1px solid rgba(255, 255, 255, .15);
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    font-size: 18px;
    position: fixed;
    background-color:transparent;
    transition:.3s;
    z-index: 60;
}


.burger_overlay{
    position: fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    z-index:1050;
    background-color:#272727;
    touch-action: none;
    
}

.burger_wrapper button{
    float:right;
    margin-top:12px;
    margin-right: 22px;
    background-color: transparent;
    color: white;
    border: none;
    font-size: 22pt;
    font-family: "Bariol Bold", sans-serif;
}

.burger_content{
    display:flex;
    padding-top:30px;
    width:100vw;
    height: 50vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}



.burger_content ul{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    margin-top:60px;
    font-size:22pt;
    margin-left:-30px;
    text-align: center;
}

.burger_content li a{
    color:white;
    text-decoration: none;
}

.burger_wrapper button:hover{
    cursor:pointer;
}



nav .nav,
.burger_content ul{
    list-style-type: none;
}



nav .nav_element{
    display:inline;
    color:white;
    padding-left: 50px;
    opacity: 1;
    transition: opacity .1s ease-in-out;
}

nav li:hover{
    cursor: pointer;
    opacity: 0.7;
}

nav a{
    text-decoration: none;
    color:white;
}

.active{
    min-height: 60px;
    background-color:#272727!important;
}

nav button{
    background-color:transparent;
    border:none;
}

nav button:hover{
    cursor: pointer;
}