.modal_overlay{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.6);
    touch-action:none;
}

/* .modal_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
} */

.modal {
    z-index: 100;
    background-color: white;
    position: relative;
    margin: 15em auto;
    border-radius: 3px;
    max-width: 500px;
    
}

.modal_close {
    float: right;
    display: block;
    color: #98c02b;
    margin-right: 10px;
    margin-top:5px;
}



.modal_close:hover{
    cursor:pointer;
}

.modal_box{
    padding: 1rem;
    text-align: center;  
}

.modal_box p{
    font-family: 'Bariol Book',sans-serif;
    font-size:14px;
}

.modal_topic{
    font-family: 'Bariol Book', sans-serif;
    font-size: 20px;
    color:#98c02b;
}

.end_modal_topic{
    font-size:2.8em !important;
    font-family: 'Gotham Medium', sans-serif;
    color:white;
    font-weight: bolder;
    padding:0;
    margin:0;
}

.end_modal_info{
    font-family: 'Bariol Bold', sans-serif !important;
    color:white;
    font-size:1.6em !important;
    font-weight: lighter;
}

.end_winner_list{
    list-style-type: none;
    display:flex;
    justify-content: space-around;
    padding:0;
    margin-top:40px;
    
}

.end_winner_list p{
    font-family: 'Bariol Bold', sans-serif !important;
    color:white!important;
    font-size:1.5em;
    margin-left:10px;
    margin-top:10px;
    text-shadow: 1px 3px 3px rgba(0,0,0,.4);
    margin-right:20px;
}  

.end_winner_img{
    border-radius:50%;
    width: 110px;
    height: 110px;
    -webkit-box-shadow: 20px 2px 15px -14px rgba(66, 68, 90, .8);
    -moz-box-shadow: 20px 2px 15px -14px rgba(66, 68, 90, .8);
    box-shadow: 20px 2px 15px -14px rgba(66, 68, 90, .8);
    margin-right:20px;
}

@media only screen and (max-width: 450px) {
    .modal{
        margin: 6em auto;
    }
}

@media only screen and (max-height: 800px) {
    .modal{
        margin:4em auto;
    }
}