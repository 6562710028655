footer{
    height:300px;
    background-color:#272727;
    position:relative;
}

.copyrights{
    width:100%;
    border-top:1px solid lightgrey;
    position:absolute;
    bottom:0;
    height: 50px;
    color:lightgrey;    
}

.copyrights p{
    width:50vw;
    margin:0 auto;
    margin-top:15px;
}

.footer_wrapper{
    width:60vw;
    margin:0 auto;
    display:flex;
    justify-content: space-around;
    align-items: center;
    height:80%;
    color:white;
    transition: opacity 1s ease-in-out;
}

.footer_nav ul, .footer_nav a{
    text-decoration: none;
    list-style-type: none;
    color:white;
    padding:0;
    margin:0;
    opacity: 1;
}

.footer_nav li{
    margin-top:10px;
}

.footer_nav a:hover{
    opacity:0.7
}

.footer_nav{
    display:flex;
    align-items: flex-start;
}

.footer_information{
    margin-right:30px;
}

.footer_contact p{
    text-transform: uppercase;
}

.footer_logos div{
    margin-top:30px;
}

@media only screen and (max-width: 1125px) {
    footer{
        height:500px;
    }

    .footer_wrapper{
        padding-top:20px;
        display:flex;
        flex-direction: column;
    }

    .copyrights p {
        width:90vw;
        font-size:10pt;
    }

    .footer_nav{
        display:flex;
        height:200px;
        flex-direction: column;
        justify-content: space-around;
    }

}


    