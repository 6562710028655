.sendForm{
    margin-top: 60px;
    background-image: url("../assets/form.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
}

.sendForm_story{
    resize: none;
    border: none;
    border-radius: 15px;
    padding-left:10px;
    padding-top:5px;
    width:100%;
    font-family: 'Gotham Medium',sans-serif;
    font-size:16pt;
    margin-top: 10px;
    color:#c2c2c2;
}

.file_btn{
    display: inline-block;
    font-family: "Bariol Bold",sans-serif;
    background-color:#eb1784;
    width:120px;
    height: 25px;
    color:white;
    border-radius:15px;
    text-transform: uppercase;
    text-align: center;
    padding:5px;
    font-size:0.9em;
    margin-top:20px;
    transition: all .2s ease-in-out;
}

.file_btn:hover{
    cursor: pointer;
    background-color: #222222;
}

.-disabled{
    background-color:#222222!important;
}

.-disabled:hover{
    cursor: not-allowed !important;
}

form{
    width:50vw;
    margin:0 auto;
    padding-top:40px;
    padding-bottom: 10px;
}

form label{
    font-family: "Gotham Book", sans-serif;
    font-size:14pt;
}

form button{
    color:white!important;
}

input[type="radio"]{
    appearance: none;
    background-color: transparent;
    margin: 0;
    font:inherit;
    color: currentColor;
    width: 1.2em;
    height: 1.2em;
    border: 0.12em solid currentColor;
    border-radius: 50%;
    position: relative;
}

input[type="checkbox"]{
    appearance: none;
    background-color: transparent;
    margin: 0;
    color: currentColor;
    width: 1.2em;
    height: 1.2em;
    border: 0.12em solid currentColor;
    border-radius: 50%;
    position: relative;
}

input[type="radio"]::before,
input[type="checkbox"]::before{
    content: "";
    width: 0.65em;
    margin:0;
    height: 0.65em;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: black;
    -o-transform: translate(-50%,-50%) scale(0);
    -moz-transform: translate(-50%, -50%) scale(0);
    -ms-transform: translate(-50%, -50%) scale(0);
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    text-align: center;
    border-radius: 50%;
    transition: 60ms transform ease-in-out;
    box-shadow: inset 1em 1em black;
}




input[type="radio"]:checked::before,
input[type="checkbox"]:checked::before{
    transform:scale(1) translate(-50%, -50%);
}


.cerebral_palsy_agreements{
    display:flex;
    justify-content: flex-start;
    align-items: center;
}

.label_above{
    padding-left:1em;
    /* padding-bottom: 20px; */
    text-align: center;
    font-weight: bold;
}

.label_above input{
    margin-bottom: 45px;
}

.label_above p{
    font-size:15px;
}


.contact{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width:100%;
    margin-top:20px;
}

.contact input,.contact label{
    width:48%;
}

@media only screen and (max-width: 1125px) {
    .contact{
        flex-direction: column;
    }

    .contact input, .contact label{
        width:100%;
    }
}

.contact input{
    border-radius:15px;
    border:none;
    width: 100%;
    /* max-width:460px; */
    height:44px; 
    margin-top:10px;
    padding-left:10px;
    font-family: "Gotham Medium", sans-serif;
    color:#c2c2c2;
    font-size:16pt;
}


.agreements label{
    padding-left:10px;
}


.regulations {
    margin-top:40px;
    font-family: 'Gotham Narrow Book',sans-serif;
    font-size: 12pt;
}

@media only screen and (max-width: 1125px) {
    form{
        width: 90vw;
    }

}










