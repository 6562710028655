.rec-carousel-wrapper{
    /* width: 62vw !important; */
    margin:0 auto;
}

.rec-arrow-left,.rec-arrow-right{
    border-radius:0!important;
    color:#98c02b!important;
    box-shadow:none!important;
    background-color:transparent!important;
    font-size:3em!important;
    
}

.rec-arrow-left:disabled,.rec-arrow-right:disabled{
    visibility: hidden;
}

.rec-pagination{
    visibility: hidden;
}

.item{
    padding-left:2em;
    padding-top:1.5em;
}

.item_desc{
    max-width:260px;
    text-align: center;
    font-size:12pt;
}

.item_img{
    width:240px;
    height:240px;
    
}

.item_img img{
    width:100%;
    height:100%;
    object-fit: contain;
}


.alternativeGallery{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    
}

@media only screen and (max-width: 1125px) {
    .rec-carousel-wrapper {
        width: 100vw !important;
        margin: 0 auto;
    }

    .item{
        margin:30px auto;
        padding:0;
    }
    
        /* .item_img{
        width:100px;
        height:100px;
    } */

    

}