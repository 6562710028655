.content_wrapper{
    margin: 0px auto;
    width:50vw;
    text-align:center;
    padding-top:60px;
}

hgroup p{
    font-family: 'Gotham Book', sans-serif;
    font-style: normal; 
    font-size: 24pt;
}

hgroup h1,hgroup p{
    display:inline;
}

hgroup span{
    font-family: 'Gotham Bold',sans-serif;
    font-size:36pt;
}

.description{
    font-family: 'Gotham Light',sans-serif;
    width:70%;
    margin:0 auto;
    font-size:16.5pt;
    margin-top:60px;
}

article{
    color:#2b212b;
}

.btn_container{
    margin-top:60px;
    display:flex;
    justify-content: center;
}

.btn{
    color:black;
    font-family: 'Bariol Bold', sans-serif;
    font-size:1.4em;
    font-weight: 100;
    border:none;
    border-radius:15px;
    padding-left:35px;
    padding-right:35px;
    height:50px;
    margin-right: 20px;
    transition: all .2s ease-in-out;
    max-width: 400px;
}




.btn:hover{
    color:white;
    background-color:#222222!important;
}

.btn:hover{
    cursor:pointer;
}


@media only screen and (max-width: 1125px) {
    .content_wrapper{
        width: 90vw;
    }

    .btn_container{
        flex-direction: column;
    }

    .btn:last-of-type {
        margin-top: 30px;
    }
}