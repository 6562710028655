@import url('https://fonts.cdnfonts.com/css/bariol-bold');

@font-face {
  font-family: 'Gotham Bold';
  src: url('./fonts/Gotham-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Gotham Book';
  src: url('./fonts/Gotham-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Gotham Light';
  src: url('./fonts/Gotham-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Gotham Medium';
  src: url('./fonts/Gotham-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Gotham Narrow Book';
  src: url('./fonts/GothamNarrow-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Gotham Narrow Bold';
  src: url('./fonts/GothamNarrow-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #F0F0F0;
  accent-color: black;
}

*,*::before,*::after{
  box-sizing: border-box;
  scroll-behavior: smooth !important;
}

img{
  pointer-events: none;
}

p {
  font-family: 'Gotham Medium', sans-serif;
  font-style: normal;
}

h1 {
  font-family: 'Gotham Bold', sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-size: 48pt;
}

h2 {
  font-family: 'Gotham', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  font-size: 30pt;
}

h3{
  font-family: 'Gotham', sans-serif;
  text-transform: uppercase;
  font-size:1.5em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
